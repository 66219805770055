import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import styles from "../../styles/info-site/AdSpecs.module.css";
import RegularOffers from "../../assets/regular-offers.png";
import HeroOffer from "../../assets/hero-offer.png";
import IButton from "../../assets/iButton.png";
import Cta from "../styleguide/Cta";

const AdSpecsPage = () => {
    const renderTableRows = () => {
        return (
            <React.Fragment>
                {["One", "Two", "Three", "Four", "Five", "Six"].map((item, key) => (
                    <tr key={key}>
                        <td><FormattedHTMLMessage id={`adSpecsItem${item}Title`} /></td>
                        <td><FormattedHTMLMessage id={`adSpecsItem${item}Description`} /></td>
                        <td><FormattedHTMLMessage id={`adSpecsItem${item}Requirements`} /></td>
                    </tr>
                ))}
            </React.Fragment>
        )
    };

    return (
        <div className={styles.AdSpecs}>
            <h2 className={styles.AdSpecsTitle}><FormattedMessage id="adSpecsTitle" /></h2>
            <table className={styles.AdSpecsTable}>
                <thead>
                    <tr>
                        <th><FormattedMessage id="adSpecsItem" /></th>
                        <th><FormattedMessage id="adSpecsDescription" /></th>
                        <th><FormattedMessage id="adSpecsRequirements" /></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </table>

            <div className={styles.AdSpecsDownload}>
              <a href="https://drive.google.com/file/d/1oAj-d_j64-f1KDPgFe6m60j7Y35SwcCP/view?usp=sharing" target="_blank">Get the perfect head start. Download the Tyviso Ad Guide.</a>
            </div>

            <Cta className={styles.Cta} link="/contact" text="faqContactCta" onClick={() => {}} />

            <h3 className={styles.AdSpecsSubtitle}><FormattedMessage id="adSpecsSubtitleOne" /></h3>
            <div className={styles.AdSpecsWrapperImage}>
                <img className={styles.AdSpecsImage} src={RegularOffers} alt="Regular offers" />
            </div>
            <h3 className={styles.AdSpecsSubtitle}><FormattedMessage id="adSpecsSubtitleThree" /></h3>
            <div className={styles.AdSpecsWrapperImage}>
              <img className={styles.AdSpecsImage} src={IButton} alt="iButton" />
            </div>
            <h3 className={styles.AdSpecsSubtitle}><FormattedMessage id="adSpecsSubtitleTwo" /></h3>
            <img className={styles.AdSpecsImage} src={HeroOffer} alt="Hero offer" />
        </div>
    );
};

export default AdSpecsPage;