import React from "react";
import AdSpecsPage from "../components/info-site/AdSpecsPage";
import Seo from "../components/info-site/Seo";
import Layout from "../layouts/en";

const AdSpecs = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="adSpecsTitle" descriptionId="adSpecsDescription" />
            <AdSpecsPage />
        </Layout>
    );
};

export default AdSpecs;